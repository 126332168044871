import formInputFieldPropsMixin from './formInputFieldProps';

export default {
  mixins: [formInputFieldPropsMixin],
  props: {
    value: {
      default: null,
    },
    checked: {},
    confirmRequired: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'Confermi?',
    },
  },
  model: {
    prop: 'checked',
    event: 'input',
  },
};
