<template>
  <FormInputWidget
    :id="inputId"
    :notRequired="notRequired"
    :hasError="hasError" :errorMsg="errorMsg"
  >
    <template v-if="$slots.label" #label="{ label, notRequired }">
      <slot name="label" :label="label" :notRequired="notRequired" />
    </template>

    <FormInputCheckbox :id="inputId" :notRequired="notRequired" :placeholder="placeholder" :disabled="disabled" :value="value" :checked="checked" @input="emitInput">
      <template #default>
        <slot></slot>
      </template>
    </FormInputCheckbox>
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget';
import formInputCheckboxPropsMixin from '../mixins/formInputCheckboxProps';

export default {
  mixins: [formInputWidgetMixin, formInputCheckboxPropsMixin],
  components: {
    FormInputCheckbox: () => import('../FormInputCheckbox'),
  },
};

</script>
